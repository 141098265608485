var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.popupData
    ? _c("div", { staticClass: "app-pop-up" }, [
        _vm.showClosePopup
          ? _c("div", {
              staticClass: "app-pop-up__close",
              on: {
                click: function ($event) {
                  return _vm.eventHub.$emit("showPopupMobileReg", false)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "app-pop-up__inner" }, [
          _c("img", {
            staticClass: "app-pop-up__bg",
            attrs: { src: _vm.popupData.IMAGEFON, alt: "bgfon" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "app-pop-up__img",
            attrs: { src: _vm.popupData.IMAGE, alt: "image" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "app-pop-up__logo",
            attrs: { src: _vm.popupData.LOGO, alt: "logobanner" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "app-pop-up__btns" }, [
            _c(
              "a",
              {
                staticClass: "app-pop-up__btn",
                attrs: { href: _vm.popupData.GOOGLEPLAY_LINK },
              },
              [
                _c("img", {
                  attrs: { src: _vm.popupData.GOOGLEPLAY, alt: "google_play" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "app-pop-up__btn",
                attrs: { href: _vm.popupData.APPSTORE_LINK },
              },
              [
                _c("img", {
                  attrs: { src: _vm.popupData.APPSTORE, alt: "app_store" },
                }),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }