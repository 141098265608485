var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "loading-dialog", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("div", { staticClass: "loading-dialog__info" }, [
        _c("img", {
          attrs: { src: require("../../../../../images/loader.gif"), alt: "" },
        }),
        _vm._v("\n        Загрузка...\n    "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }