var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { id: "footer" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "footer-wrap",
          attrs: { wrap: "", row: "", "pa-4": "", "pa-md-0": "" },
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "footer-wrap-card",
              attrs: { md12: "", lg7: "", "pr-4": "", "pl-2": "" },
            },
            [
              _vm.parameters.SHOW_APP === "Y"
                ? _c(
                    "v-card",
                    { attrs: { color: "#007DC6" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", row: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm7: "", "pl-4": "" } },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.parameters.LOGOS_APP[0],
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "ml-3",
                                    attrs: {
                                      src: _vm.parameters.LOGOS_APP[1],
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "footer-text-blue",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.parameters.DESCRIPTION_APP
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "footer-btns-download",
                                  attrs: { xs12: "", sm5: "" },
                                },
                                _vm._l(
                                  _vm.parameters.COUNT_LINKS_INSTALL_APP,
                                  function (count, i) {
                                    return _c(
                                      "v-btn",
                                      {
                                        key: "link_" + i,
                                        class: {
                                          "mb-3":
                                            _vm.parameters.SETTINGS_INSTALL_APP
                                              .ADD_CLASS,
                                        },
                                        attrs: {
                                          round: "",
                                          outline: "",
                                          color: "#ffffff",
                                          block: "",
                                          flat: "",
                                          href: _vm.parameters
                                            .SETTINGS_INSTALL_APP.LINKS[
                                            count - 1
                                          ],
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.parameters
                                              .SETTINGS_INSTALL_APP.LOGOS[
                                              count - 1
                                            ],
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.parameters.FIRST_BUTTON_SHOW === "Y" ||
              _vm.parameters.SECOND_BUTTON_SHOW === "Y"
                ? _c(
                    "v-layout",
                    {
                      staticClass: "footer-btns-part",
                      attrs: { row: "", wrap: "", "mt-2": "", "px-3": "" },
                    },
                    [
                      _vm.parameters.FIRST_BUTTON_SHOW === "Y"
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "pr-2": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "footer-btn",
                                  attrs: {
                                    outline: "",
                                    href: _vm.parameters.FIRST_BUTTON_LINK,
                                    color: "#2f2e2e",
                                    block: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "pr-2",
                                      attrs: { color: "#007DC6" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.parameters.FIRST_BUTTON_ICON)
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.parameters.FIRST_BUTTON_TITLE
                                      ) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.parameters.SECOND_BUTTON_SHOW
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", "pl-2": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outline: "",
                                    href: _vm.parameters.SECOND_BUTTON_LINK,
                                    color: "#2f2e2e",
                                    block: "",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "pr-2",
                                      attrs: { color: "#007DC6" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.parameters.SECOND_BUTTON_ICON
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.parameters.SECOND_BUTTON_TITLE
                                      ) +
                                      "\n                    "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.parameters.SHOW_CONTACTS
            ? _c(
                "v-flex",
                { attrs: { md12: "", lg5: "", "pt-4": "", "pl-3": "" } },
                [
                  _c("div", { staticClass: "footer-infoline" }, [
                    _vm._v(_vm._s(_vm.parameters.TITLE_CONTACTS)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "footer-phones" },
                    _vm._l(_vm.parameters.COUNT_NUMBERS, function (count) {
                      return _c(
                        "div",
                        {
                          class:
                            _vm.parameters.SETTINGS_NUMBERS.ICONS[count - 1],
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.parameters.SETTINGS_NUMBERS.NUMBERS[
                                  count - 1
                                ],
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.parameters.SETTINGS_NUMBERS.VIEW_NUMBERS[
                                    count - 1
                                  ]
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "footer-work-time",
                    domProps: {
                      innerHTML: _vm._s(_vm.parameters.SCHEDULE_CONTACTS),
                    },
                  }),
                  _vm._v(" "),
                  _vm.parameters.SHOW_MENU === "Y"
                    ? _c(
                        "div",
                        { staticClass: "footer-hrefs" },
                        _vm._l(_vm.menuFooter, function (item, i) {
                          return _c(
                            "a",
                            {
                              key: "footer_href_" + i,
                              attrs: { href: item.href },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.text) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "px-3": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [_c("LineDotts", { attrs: { color: "#ececec" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { wrap: "", row: "", "pa-4": "", "pt-4": "" } },
        [
          _c("v-flex", { attrs: { md12: "", lg10: "", "pr-1": "" } }, [
            _c("div", {
              staticClass: "footer-bottom-text",
              domProps: { innerHTML: _vm._s(_vm.parameters.TEXT_COPYRIGHT) },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { md12: "", lg2: "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-center": "",
                    row: "",
                    "fill-height": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        "align-center": "",
                        "text-center": "",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "footer-img",
                        attrs: { src: _vm.parameters.IMG_FOOTER, alt: "" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.kupilkaData
        ? _c(
            "v-layout",
            {
              staticClass: "layout kupilka-popup",
              class: _vm.kupilkaData.HIDE_TEXT == "Y" ? "marginRight" : "",
            },
            [
              _c(
                "a",
                { attrs: { href: _vm.kupilkaData.LINK } },
                [
                  _vm.kupilkaData.HIDE_TEXT == "N"
                    ? _c(
                        "v-layout",
                        {
                          staticClass:
                            "layout kupilka-popup__text-wrapper-shadow",
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "layout kupilka-popup__text-wrapper",
                            },
                            [
                              _c(
                                "v-layout",
                                { staticClass: "layout kupilka-popup__text" },
                                [
                                  _vm.kupilkaData.TITLE !== ""
                                    ? _c("p", {
                                        staticClass: "kupilka-popup__title",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.kupilkaData.TITLE
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.promotionTimeLeft > 0
                                    ? _c("p", {
                                        staticClass: "kupilka-popup__day-count",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.promotionTimeLeft
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.promotionTimeLeft > 0
                                    ? _c("p", {
                                        staticClass: "kupilka-popup__day-text",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.promotionTimeLeft % 10 === 1 &&
                                              _vm.promotionTimeLeft !== 11
                                              ? "день"
                                              : _vm.promotionTimeLeft % 10 < 5
                                              ? "дня"
                                              : "дней"
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.promotionTimeLeft <= 0 &&
                                  _vm.kupilkaData.TEXT !== ""
                                    ? _c("p", {
                                        staticClass:
                                          "kupilka-popup__day-text small-text",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.kupilkaData.TEXT
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("img", {
                    attrs: { src: _vm.kupilkaData.IMAGE, alt: "kupilka" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-btn", {
                staticClass: "kupilka-popup__close-btn",
                on: {
                  click: function ($event) {
                    return _vm.setKupilkaPopUpVisibility(false, null)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.kupilkaDataLK
        ? _c(
            "v-layout",
            { staticClass: "layout kupilka-popup mod" },
            [
              _c(
                "v-layout",
                {
                  staticClass: "layout kupilka-popup__text-wrapper-shadow mod",
                },
                [
                  _c(
                    "v-layout",
                    { staticClass: "layout kupilka-popup__text-wrapper" },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "layout kupilka-popup__text" },
                        [
                          _vm.kupilkaDataLK.TITLE !== ""
                            ? _c("p", {
                                staticClass: "kupilka-popup__title text-mod",
                                domProps: {
                                  innerHTML: _vm._s(_vm.kupilkaDataLK.TITLE),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentURL
                            ? _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "scroll-to",
                                      rawName: "v-scroll-to",
                                      value: "#section-one",
                                      expression: "'#section-one'",
                                    },
                                  ],
                                  staticClass: "kupilka-popup__btn orange",
                                  attrs: {
                                    to: _vm.kupilkaDataLK.LINK + "#section-one",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showKupilkaLk("router-link")
                                    },
                                  },
                                },
                                [
                                  _vm.kupilkaDataLK.TEXT_LINK !== ""
                                    ? _c("div", {
                                        staticClass:
                                          "kupilka-popup__btn text-btn",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.kupilkaDataLK.TEXT_LINK
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentURL == ""
                            ? _c(
                                "a",
                                {
                                  staticClass: "kupilka-popup__btn orange",
                                  attrs: {
                                    href:
                                      _vm.kupilkaDataLK.LINK + "#section-one",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showKupilkaLk("")
                                    },
                                  },
                                },
                                [
                                  _vm.kupilkaDataLK.TEXT_LINK !== ""
                                    ? _c("div", {
                                        staticClass:
                                          "kupilka-popup__btn text-btn",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.kupilkaDataLK.TEXT_LINK
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("img", {
                attrs: { src: _vm.kupilkaDataLK.IMAGE, alt: "kupilka" },
              }),
              _vm._v(" "),
              _c("v-btn", {
                staticClass: "kupilka-popup__close-btn",
                on: {
                  click: function ($event) {
                    return _vm.setKupilkaPopUpVisibilityLK(false, null)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }