export default {
  data () {
    return {
      show: false
    }
  },
  name: 'Loading',
  mounted () {
    this.eventHub.$on('Loading', (is) => {
      is ? this.open() : this.close()
    })
  },
  methods: {
    open () {
      this.show = true
    },
    close () {
      setTimeout(() => {
        this.show = false
      }, 300)
    }
  }
}
