var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { id: "line-dotts" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "overflow-hidden": "" } },
        _vm._l(100, function (i) {
          return _c(
            "v-icon",
            { key: i, staticClass: "mr-2 ml-1", attrs: { color: _vm.color } },
            [_vm._v("fiber_manual_record")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }