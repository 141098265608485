var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    { ref: "header" },
    [
      _vm.drawer
        ? _c(
            "v-btn",
            {
              staticClass: "mobile-nav-close-btn",
              attrs: { flat: "", icon: "", color: "#ffffff" },
              on: {
                click: function ($event) {
                  _vm.drawer = false
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { temporary: "", fixed: "", width: "280", height: "150%" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            { staticClass: "mobile-nav-header" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs4: "" } },
                    [
                      _c("v-img", {
                        staticClass: "mobile-nav-header-img",
                        attrs: {
                          src: _vm.parameters.IMG_LOGO_PERSONAL_CABINET,
                          contain: "",
                          width: "62",
                          height: "40",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-flex", { attrs: { xs8: "" } }, [
                    _vm.userIsLogin
                      ? _c("div", { staticClass: "mobile-nav-header-text" }, [
                          _c("a", {
                            attrs: { href: _vm.parameters.LINK_TO_LK },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.parameters.TITLE_PERSONAL_CABINET
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.personName) },
                          }),
                          _vm._v(" "),
                          _vm.cartScore
                            ? _c("div", {
                                staticClass: "count-score-mobile",
                                domProps: {
                                  textContent: _vm._s(_vm.cartScore),
                                },
                              })
                            : _vm._e(),
                        ])
                      : _c("div", {
                          staticClass: "mobile-nav-header-text",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.parameters.TITLE_PERSONAL_CABINET
                            ),
                          },
                        }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pt-2": "" } },
                    [
                      !_vm.userIsLogin
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn--sosedi small blue",
                              attrs: { block: "" },
                              on: {
                                click: function ($event) {
                                  _vm.modalLogin = true
                                },
                              },
                            },
                            [_vm._v("\n            ВОЙТИ\n          ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "btn--sosedi small blue",
                              attrs: { href: "/?logout=yes", block: "" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.by ? "ВЫЙСЦІ" : "ВЫЙТИ") +
                                  "\n          "
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "500" },
                          model: {
                            value: _vm.modalLogin,
                            callback: function ($$v) {
                              _vm.modalLogin = $$v
                            },
                            expression: "modalLogin",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { id: "mobileLoginForm" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-close-login",
                                  attrs: { flat: "", icon: "", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.modalLogin = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            { staticClass: "pt-2 mobile-nav", attrs: { dense: "" } },
            [
              _vm._l(_vm.menu, function (item, i) {
                return _c(
                  "v-list-group",
                  {
                    key: i,
                    attrs: {
                      "append-icon": !item.subMenu ? "" : "keyboard_arrow_down",
                    },
                  },
                  [
                    item.subMenu
                      ? _c(
                          "v-list-tile",
                          {
                            staticClass: "mobile-nav-item-with-icon",
                            attrs: { slot: "activator" },
                            slot: "activator",
                          },
                          [
                            _c(
                              "v-list-tile-action",
                              { staticClass: "v-list-tile-action-img" },
                              [
                                _c("div", { staticClass: "left-nav-icon" }, [
                                  _c("img", {
                                    attrs: { src: item.icon, alt: "" },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            item.subMenu
                              ? _c(
                                  "v-list-tile-title",
                                  { staticClass: "mobile-nav-text-icon" },
                                  [_vm._v(_vm._s(item.text))]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "v-list-tile",
                          {
                            staticClass: "mobile-nav-item-with-icon",
                            attrs: {
                              slot: "activator",
                              tag: "a",
                              href: item.href,
                            },
                            slot: "activator",
                          },
                          [
                            _c(
                              "v-list-tile-action",
                              { staticClass: "v-list-tile-action-img" },
                              [
                                _c("div", { staticClass: "left-nav-icon" }, [
                                  _c("img", {
                                    attrs: { src: item.icon, alt: "" },
                                  }),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-tile-title",
                              { staticClass: "mobile-nav-text-icon" },
                              [_vm._v(_vm._s(item.text))]
                            ),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _vm._l(item.subMenu, function (subItem, j) {
                      return _c(
                        "v-list-tile",
                        { key: j, staticClass: "mobile-subNav" },
                        [
                          subItem.onlyUpdateMap
                            ? _c(
                                "v-list-tile-title",
                                {
                                  staticClass: "mobile-subNav-text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editMap(subItem)
                                    },
                                  },
                                },
                                [
                                  _c("a", { attrs: { href: subItem.href } }, [
                                    _vm._v(_vm._s(subItem.text)),
                                  ]),
                                ]
                              )
                            : _c(
                                "v-list-tile-title",
                                { staticClass: "mobile-subNav-text" },
                                [
                                  _c("a", { attrs: { href: subItem.href } }, [
                                    _vm._v(_vm._s(subItem.text)),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mobile-nav-item-without-icon" },
                _vm._l(_vm.menuBottom, function (item, i) {
                  return _c(
                    "v-list-tile",
                    { key: "menu_item_" + i },
                    [
                      _c("v-list-tile-title", [
                        _c("a", {
                          staticClass: "mobile-nav-text",
                          attrs: { href: item.href },
                          domProps: { textContent: _vm._s(item.text) },
                        }),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mobile-nav-searc" },
                [
                  _c(
                    "v-form",
                    {
                      attrs: { action: "/search?q=" + _vm.searchText },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.goToSearch()
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.goToSearch()
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchText,
                            expression: "searchText",
                          },
                        ],
                        staticClass: "style--sosedi-input",
                        attrs: {
                          type: "search",
                          placeholder: _vm.parameters.PLACEHOLDER_SEARCH,
                          autocomplete: "on",
                        },
                        domProps: { value: _vm.searchText },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchText = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.search
          ? _c(
              "div",
              {
                staticClass: "header-search",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "v-container",
                  { attrs: { wrap: "", row: "" } },
                  [
                    _c(
                      "v-layout",
                      [
                        _c("v-flex", { attrs: { md3: "", lg2: "" } }, [
                          _c(
                            "a",
                            {
                              staticClass: "d-block header-search-logo",
                              attrs: { href: "#" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/local/assets/images/logo-sosedi-small.svg",
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { md9: "", lg10: "" } },
                          [
                            _c(
                              "v-form",
                              {
                                staticClass: "header-search-form",
                                attrs: {
                                  action: "/search?q=" + _vm.searchText,
                                },
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.goToSearch()
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.goToSearch()
                                  },
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.searchText,
                                      expression: "searchText",
                                    },
                                  ],
                                  staticClass: "header-search-input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      _vm.parameters.PLACEHOLDER_SEARCH,
                                  },
                                  domProps: { value: _vm.searchText },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.searchText = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "header-search-closeBtn",
                                attrs: {
                                  flat: "",
                                  icon: "",
                                  large: "",
                                  color: "#ffffff",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.search = false
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v("\n    Для вызова поиска нажмите Ctrl + Shift + F\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "blue", flat: "", icon: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          staticClass: "controlAlertMessage",
          attrs: {
            bottom: "",
            left: "",
            "auto-height": "",
            timeout: _vm.timeout,
            vertical: "",
            color: _vm.alertMessage.success ? "#6ce26c" : "#ff6868",
          },
          on: { click: _vm.handleLineClick },
          model: {
            value: _vm.controlAlertMessage,
            callback: function ($$v) {
              _vm.controlAlertMessage = $$v
            },
            expression: "controlAlertMessage",
          },
        },
        [
          typeof _vm.alertMessage.message == "string"
            ? [_vm._v("\n      " + _vm._s(_vm.alertMessage.message) + "\n    ")]
            : _vm._l(_vm.alertMessage.message, function (item, key) {
                return _c(
                  "div",
                  { key: key },
                  [
                    item.name !== ""
                      ? [_vm._v(_vm._s(item.name) + " : ")]
                      : _vm._e(),
                    _c("span", { domProps: { innerHTML: _vm._s(item.value) } }),
                  ],
                  2
                )
              }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "#ffffff", flat: "", dark: "" },
              on: {
                click: function ($event) {
                  _vm.controlAlertMessage = false
                },
              },
            },
            [_vm._v("\n      Закрыть\n    ")]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-container",
        {
          ref: "container",
          staticClass: "header-container",
          class: { _fixed: _vm.fixed },
          attrs: { "grid-list-md": "", "text-xs-cente": "" },
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("LeftNav", {
                attrs: {
                  menu: _vm.menu,
                  menuBottom: _vm.menuBottom,
                  parameters: _vm.parameters,
                },
              }),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs2: "", "hidden-lg-and-up": "", "text-left": "" } },
                [
                  _c("v-toolbar-side-icon", {
                    staticClass: "mobile-btn-nav",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.drawer = !_vm.drawer
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  staticClass: "main-logo",
                  attrs: {
                    "hidden-lg-and-up": "",
                    "position-relative": "",
                    "text-center": "",
                  },
                },
                [
                  _c("a", { attrs: { href: _vm.parameters.LINK_LOGO } }, [
                    _c("img", {
                      staticClass: "header-logo",
                      attrs: { src: _vm.parameters.IMG_LOGO, alt: "" },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  attrs: {
                    lg4: "",
                    "hidden-md-and-down": "",
                    "offset-lg-2": "",
                    "text-left": "",
                  },
                },
                [
                  _vm.header_links
                    ? _c(
                        "div",
                        { staticClass: "header-btns" },
                        [
                          _vm._l(
                            _vm.header_links.filter((el, index) => index < 2),
                            function (item, index) {
                              return _c(
                                "a",
                                {
                                  key: index,
                                  staticClass: "header-btns__item",
                                  attrs: { href: item.link },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.img,
                                      alt: "lavanda",
                                      width: "180",
                                      height: "100",
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "header-btns__item caramel",
                              attrs: { href: "/news/99652/", target: "_blank" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/local/assets/images/caramel.svg",
                                  alt: "caramel",
                                  width: "180",
                                  height: "100",
                                },
                              }),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  staticClass: "header-right",
                  attrs: { xs2: "", lg6: "", "text-right": "" },
                },
                [
                  _c("div", { staticClass: "header-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header-my-count-kupilka hidden-md-and-down",
                      },
                      [
                        _vm.userIsLogin
                          ? _c(
                              "a",
                              {
                                staticClass: "top_text",
                                attrs: { href: _vm.parameters.LINK_TO_LK },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.parameters
                                      .IMG_LOGO_PERSONAL_CABINET,
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showLogin = !_vm.showLogin
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.parameters
                                      .IMG_LOGO_PERSONAL_CABINET,
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                        _vm._v(" "),
                        _vm.userIsLogin
                          ? _c(
                              "div",
                              { staticClass: "kupilka-text-login" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-inline-block position-relative block-name-user",
                                  },
                                  [
                                    _vm.cartScore
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "count-score-in-title",
                                          },
                                          [_vm._v(_vm._s(_vm.cartScore))]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("a", {
                                      staticClass: "top_text",
                                      attrs: {
                                        href: _vm.parameters.LINK_TO_LK,
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.parameters.TITLE_PERSONAL_CABINET
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.personName),
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "ml-2 btn btn-blue btn--sosedi small blue header-login-kupilka",
                                    attrs: { href: "/?logout=yes" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.by ? "ВЫЙСЦІ" : "Выйти") +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", {
                              staticClass: "kupilka-text",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.parameters.TITLE_PERSONAL_CABINET
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showLogin = !_vm.showLogin
                                },
                              },
                            }),
                        _vm._v(" "),
                        !_vm.userIsLogin
                          ? _c("div", { staticClass: "popup-login-container" })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.userIsLogin
                          ? _c(
                              "v-menu",
                              {
                                attrs: {
                                  "allow-overflow": "",
                                  "offset-y": "",
                                  bottom: "",
                                  "nudge-bottom": "30",
                                  "nudge-left": "126",
                                  "min-width": "320",
                                  "max-width": "320",
                                  "close-on-content-click": false,
                                  "close-on-click": false,
                                  transition: "slide-y-transition",
                                  attach: ".popup-login-container",
                                },
                                model: {
                                  value: _vm.showLogin,
                                  callback: function ($$v) {
                                    _vm.showLogin = $$v
                                  },
                                  expression: "showLogin",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "btn btn-blue btn--sosedi small blue header-login-kupilka",
                                    attrs: { slot: "activator" },
                                    slot: "activator",
                                  },
                                  [
                                    _vm._v(
                                      "\n                Войти\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-close-login",
                                    attrs: { flat: "", icon: "", dark: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showLogin = false
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("close")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "popup-login",
                                    attrs: { color: "#007DC6" },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                staticClass: "w-100",
                                                on: {
                                                  submit: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.submitLogin()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "btn--sosedi big orange",
                                                        attrs: { block: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            ;(_vm.showLogin = false),
                                                              _vm.isMobilePopup
                                                                ? ((_vm.showPopupRegistration = true),
                                                                  (_vm.modalLogin = false),
                                                                  (_vm.clickLogin = true))
                                                                : (_vm.showLoginAccount = true)
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          ВОЙТИ\n                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs12: "",
                                                  "text-center": "",
                                                  "pt-2": "",
                                                  "pb-3": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "#",
                                                      color: "#ffffff",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        ;(_vm.showLogin = false),
                                                          _vm.isMobilePopup
                                                            ? ((_vm.showPopupRegistration = true),
                                                              (_vm.modalLogin = false),
                                                              (_vm.clickLogin = false))
                                                            : (_vm.showRegistration = true)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        ЗАРЕГИСТРИРОВАТЬСЯ\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.header_links
                      ? _c(
                          "div",
                          { staticClass: "header-store header-store--desktop" },
                          _vm._l(
                            _vm.header_links.filter((el, index) => index > 1),
                            function (item, index) {
                              return _c(
                                "a",
                                {
                                  key: index,
                                  staticClass: "header-store__item",
                                  attrs: { href: item.link, target: "_blank" },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.img,
                                      alt: "AppStore",
                                      width: "120",
                                      height: "60",
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "header-search-btn hidden-md-and-down",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            _vm.search = true
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/local/assets/images/icons/ic-search.svg",
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Loading"),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000", "content-class": "register-modal" },
          model: {
            value: _vm.showRegistration,
            callback: function ($$v) {
              _vm.showRegistration = $$v
            },
            expression: "showRegistration",
          },
        },
        [
          _vm.showRegistration
            ? _c("Registration", { attrs: { policy: _vm.policy } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "1000",
            "content-class": "register-modal mobile-popup",
          },
          model: {
            value: _vm.showPopupRegistration,
            callback: function ($$v) {
              _vm.showPopupRegistration = $$v
            },
            expression: "showPopupRegistration",
          },
        },
        [
          _vm.showPopupRegistration
            ? _c("PopupMobileReg", { attrs: { popupData: _vm.popupData } })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1000" },
          model: {
            value: _vm.showLoginAccount,
            callback: function ($$v) {
              _vm.showLoginAccount = $$v
            },
            expression: "showLoginAccount",
          },
        },
        [_c("LoginAccount")],
        1
      ),
      _vm._v(" "),
      _c("ForgotPassword"),
      _vm._v(" "),
      _c("div", { staticClass: "header-mobile" }, [
        _vm.header_links
          ? _c(
              "div",
              { staticClass: "header-btns" },
              _vm._l(
                _vm.header_links.filter((el, index) => index < 2),
                function (item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      staticClass: "header-btns__item",
                      attrs: { href: item.link },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.img,
                          alt: "lavanda",
                          width: "180",
                          height: "100",
                        },
                      }),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.header_links
          ? _c(
              "div",
              { staticClass: "header-store" },
              _vm._l(
                _vm.header_links.filter((el, index) => index > 1),
                function (item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      staticClass: "header-store__item",
                      attrs: { href: item.link, target: "_blank" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: item.img,
                          alt: "AppStore",
                          width: "120",
                          height: "60",
                        },
                      }),
                    ]
                  )
                }
              ),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "header-btns__item caramel",
        attrs: { href: "/news/99652/", target: "_blank" },
      },
      [
        _c("img", {
          attrs: {
            src: "/local/assets/images/caramel.svg",
            alt: "caramel",
            width: "180",
            height: "100",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }