import BaseComponent from '../../../BaseComponent'
import LeftNav from '../../navs/LeftNav/component.vue'
import Loading from '../../extends/Loading/component.vue'
import LoginAccount from '../../extends/LoginAccount/component.vue'
import Registration from '../../extends/Registration/component.vue'
import PopupMobileReg from '../../extends/PopupMobileReg/component.vue'
import ForgotPassword from '../../extends/ForgotPassword/component.vue'
import axios from 'axios'
import MaskedInput from 'vue-masked-input'
import VueMask from 'v-mask'

export default {
  name: 'Header',
  extends: BaseComponent,
  data () {
    return {
      by: null,
      timeout: 3333,
      searchText: '',
      cartScore: null,
      personName: null,
      inputHidden: null,
      userIsLogin: null,
      menu: null,
      menuBottom: null,
      header_links: null,
      countInCard: null,
      popupData: null,
      showRegistration: false,
      authPage: false,
      isMobilePopup: false,
      showPopupRegistration: false,
      clickLogin: false,
      policy: [],
      showLoginAccount: false,
      drawer: false,
      search: false,
      snackbar: false,
      showLogin: false,
      modalLogin: false,
      parameters: null,
      controlAlertMessage: false,
      rememberMe: true,
      mask: '',
      alertMessage: {
        success: false,
        message: ''
      },
      loginForm: {
        login: '',
        password: ''
      },
      fixed: null,
      hasfon: document.querySelector('#app.hasfon')
    }
  },
  components: {
    LeftNav,
    Registration,
    PopupMobileReg,
    LoginAccount,
    ForgotPassword,
    Loading,
    MaskedInput,
    VueMask
  },
  mounted () {
    if (this.isMobile()) {
      axios.get('/local/api/mobileRegPopup.php').then((res) => {
        if (res.data.SHOW === 'Y') {
          this.isMobilePopup = true
          this.popupData = res.data
        } else {
          this.isMobilePopup = false
        }
      }).catch(err => console.log(err))
    } else {
      this.isMobilePopup = false
    }
    this.eventHub.$emit('Loading', true)
    this.eventHub.$data.userPersonByLang = this.by
    this.eventHub.$data.globalUserIsLogin = this.userIsLogin
    if (this.inputHidden) {
      this.showForgotPassword()
    }
    this.eventHub.$on('authPage', val => {
      if (val) {
        this.authPage = val
        this.showPopupRegistration = true
        this.clickLogin = true
        this.showRegistration = false
      } else {
        this.clickLogin = false
        this.authPage = false
      }
    })
    this.eventHub.$on('showPopupMobileReg', val => {
      if (val) {
        if (this.popupData !== null) {
          this.showPopupRegistration = true
          this.showRegistration = false
        } else {
          this.showPopupRegistration = false
          if (this.clickLogin === true) {
            if (!this.authPage) this.showLoginAccount = true
          } else {
            this.showRegistration = true
          }
        }
      } else {
        this.showPopupRegistration = false
        if (this.clickLogin === true) {
          if (!this.authPage) this.showLoginAccount = true
        } else {
          this.showRegistration = true
        }
      }
    })
    this.eventHub.$on('showRegistration', val => {
      this.showRegistration = val
    })
    this.eventHub.$on('showLoginAccount', val => {
      this.showLoginAccount = val
    })
    this.eventHub.$on('closePopupInIOS', () => {
      this.drawer = false
    })
    this.eventHub.$on('putProductInList', (data) => {
      if (data.new) {
        this.putProductInList(data.listID, data.productID, data.count)
      } else {
        this.changeCountProductInList(data.listID, data.productID, data.count)
      }
    })
    this.eventHub.$on('deleteProductFromList', (data) => {
      this.deleteProductFromList(data.listID, data.productID)
    })
    this.eventHub.$on('updateCountInCard', () => {
      this.getCountProductsInList()
    })
    this.eventHub.$on('ShowAlertMessage', (data) => {
      this.controlAlertMessage = false
      this.controlAlertMessage = true
      this.alertMessage = data

      if (!data.success) {
        this.timeout = 10000
      }
      // eslint-disable-next-line no-sequences
    })
    this.eventHub.$on('ShowAuthFormHeader', (data) => {
      this.showLogin = true
      this.loginForm.login = data.phone
    })
    this.eventHub.$on('ShowAuthFormHeaderPartner', (data) => {
      this.showLogin = true
      this.modalLogin = true
      this.loginForm.login = data.phone
    })
    this.instectPositionLoginForm()
    let startScroll = 0
    if (window.innerWidth > 700) {
      window.addEventListener('scroll', (event) => {
        if (!this.search) startScroll = window.scrollY
        setTimeout(() => {
          if (Math.abs(startScroll - window.scrollY) > 300) {
            startScroll = window.scrollY
            this.search = false
            this.snackbar = true
            setTimeout(() => {
              this.snackbar = false
            }, 3000)
          }
        }, 700)
      }, true)
    }
    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.shiftKey && event.keyCode === 70) {
        this.search = !this.search
      }
      if (event.keyCode === 27) {
        this.search = false
      }
    }, true)
    document.body.addEventListener('click', (event) => {
      if (event.srcElement.closest('.header-search') !== null) return
      this.search = false
    }, true)
    if (this.hasfon) {
      window.addEventListener('scroll', this.fixedHandler)
      this.fixedHandler()
    }
  },
  created () { this.isMobile() },
  methods: {
    isMobile () {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    changeInput () {
      this.mask = ['+375(', /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
    },
    handleLineClick (e) {
      console.log('handleLineClick')
      let clickedElId = e.target.id
      if (clickedElId === 'to_register') {
        e.preventDefault()
        window.location.href = '/personal/#registration'
        this.eventHub.$emit('showRegForm', false)
      } else if (clickedElId === 'to_appeals') {
        e.preventDefault()
        document.querySelector('.navigation button:nth-child(6)').click()
      }
    },
    goToSearch () {
      window.location.href = '/search?q=' + this.searchText
    },
    submitLogin () {
      axios.post('/local/api/loginPerson.php', {
        rememberMe: this.rememberMe,
        login: this.loginForm.login,
        password: this.loginForm.password
      }).then(res => {
        if (res.status === 204) {
          this.eventHub.$emit('ShowAlertMessage', {
            success: false,
            message: 'Вы ввели неверный пароль. Попробуйте восстановить пароль'
          })
        } else {
          this.eventHub.$emit('ShowAlertMessage', {
            success: true,
            message: 'Вы успешно вошли!'
          })
          setTimeout(() => {
            window.location.reload()
          }, 333)
        }
      }).catch(e => {
        console.log(e)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
      })
    },
    goToPage (url) {
      window.location.href = url
    },
    editMap (route) {
      this.eventHub.$emit('updateMap', route.href)
      this.drawer = false
    },
    instectPositionLoginForm () {
      if (window.innerWidth < 1264) {
        let loginForm = document.querySelector('.popup-login.v-card')
        document.getElementById('mobileLoginForm').appendChild(loginForm)
      }
    },
    showForgotPassword () {
      this.showLogin = false
      this.eventHub.$emit('showForgotPass', {
        show: true,
        phone: this.loginForm.login
      })
    },
    getCountProductsInList () {
      axios.get('/local/api/checkCountProductOnListNow.php').then(res => {
        this.countInCard = res.data.count
        this.eventHub.$emit('needUpadateListProductAfterChange')
      }).catch(e => console.log(e))
    },
    putProductInList (listID, productID, count) {
      axios.put('/local/api/personListProductAction.php', {
        listID: listID || 0,
        productID: productID,
        count: count
      }).then(res => {
        this.getCountProductsInList()
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Товар добавлен в список!'
        })
      }).catch(e => {
        console.log(e)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: 'Что-то пошло не так'
        })
      })
    },
    changeCountProductInList (listID, productID, count) {
      axios.post('/local/api/personListProductAction.php', {
        listID: listID,
        productID: productID,
        count: count
      }).then(res => {
        this.getCountProductsInList()
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Количество товара успешно изменено!'
        })
      }).catch(e => {
        console.log(e)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: 'Что-то пошло не так'
        })
      })
    },
    deleteProductFromList (listID, productID) {
      axios.delete('/local/api/personListProductAction.php', {
        data: {
          listID: listID,
          productID: productID
        }
      }).then(res => {
        this.getCountProductsInList()
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Продукт удален из списка'
        })
      }).catch(e => {
        console.log(e)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: 'Что-то пошло не так'
        })
      })
    },
    fixedHandler () {
      if (this.$refs.header) {
        const top = this.$refs.header.getBoundingClientRect().top
        if (top <= 0) {
          this.fixed = true
        } else {
          this.fixed = false
        }
      }
    }
  }
}
