var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "registration position-relative",
          attrs: { "max-width": "1000" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-close",
              attrs: { flat: "", icon: "" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
          _vm._v(" "),
          _c("v-layout", [
            _c("div", { staticClass: "title-section mb-3" }, [
              _vm._v("Восстановление пароля"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "section-form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _vm.changePass
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm3: "", md2: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-name-field d-flex align-center",
                              },
                              [_vm._v("Новый пароль:")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm5: "", md4: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "form-input",
                              attrs: {
                                "append-icon": _vm.showPass1
                                  ? "visibility_off"
                                  : "visibility",
                                type: _vm.showPass1 ? "text" : "password",
                                loading: "",
                                "single-line": "",
                                solo: "",
                                height: "33",
                              },
                              on: {
                                "click:append": function ($event) {
                                  _vm.showPass1 = !_vm.showPass1
                                },
                              },
                              model: {
                                value: _vm.formData.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "password", $$v)
                                },
                                expression: "formData.password",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm3: "", md2: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-name-field d-flex align-center",
                              },
                              [_vm._v("Пароль еще раз:")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm5: "", md4: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "form-input",
                              attrs: {
                                "append-icon": _vm.showPass2
                                  ? "visibility_off"
                                  : "visibility",
                                type: _vm.showPass2 ? "text" : "password",
                                loading: "",
                                "single-line": "",
                                solo: "",
                                height: "33",
                              },
                              on: {
                                "click:append": function ($event) {
                                  _vm.showPass2 = !_vm.showPass2
                                },
                              },
                              model: {
                                value: _vm.formData.passwordRepeat,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "passwordRepeat", $$v)
                                },
                                expression: "formData.passwordRepeat",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.checkCode
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm3: "", md2: "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-name-field d-flex align-center",
                              },
                              [_vm._v("Введите код")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm5: "", md4: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "form-input",
                              attrs: {
                                loading: "",
                                "single-line": "",
                                solo: "",
                                height: "33",
                                rules: [_vm.rules.required],
                                label: "Код",
                              },
                              model: {
                                value: _vm.formData.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "code", $$v)
                                },
                                expression: "formData.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "", sm3: "" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-name-field d-flex align-center",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.phone
                                    ? "Подтвердите ваш номер телефона"
                                    : "Введите ваш номер телефона"
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", sm5: "" } },
                          [
                            _c("masked-input", {
                              staticClass: "form-input-mask",
                              attrs: {
                                mask: "\\+\\375 (11) 1111111",
                                placeholder: "Ваш номер телефона",
                                type: "tel",
                                "single-line": "",
                                solo: "",
                                height: "33",
                              },
                              model: {
                                value: _vm.phone,
                                callback: function ($$v) {
                                  _vm.phone = $$v
                                },
                                expression: "phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
              _vm._v(" "),
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", sm3: "" } }),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm5: "" } },
                    [
                      _c("v-btn", {
                        staticClass: "btn--sosedi orange big",
                        attrs: { type: "submit" },
                        domProps: {
                          textContent: _vm._s(
                            !_vm.changePass
                              ? "Подтвердить"
                              : "Сохранить новый пароль"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }