import BaseComponent from '../../../BaseComponent'
import LineDotts from '../../extends/LineDotts/component.vue'
import axios from 'axios'

export default {
  name: 'Footer',
  extends: BaseComponent,
  data () {
    return {
      prettyLinks: null,
      cards: null,
      footerMenu: null,
      socialNetworks: null,
      parameters: null,
      menuFooter: null,
      kupilkaData: null,
      kupilkaDataLK: null,
      promotionTimeLeft: null,
      currentURL: null,
      showKupilka: false
    }
  },
  components: {
    LineDotts
  },
  methods: {
    setKupilkaPopUpVisibility (bool, data) {
      this.kupilkaData = data
      sessionStorage.setItem('visited', 'true')
    },
    setKupilkaPopUpVisibilityLK (bool, data) {
      this.kupilkaDataLK = data
      sessionStorage.setItem('lk_visited', 'true')
    },
    showKupilkaLk (flag) {
      if (flag) {
        document.querySelector('.kupilka-popup-small.hide').classList.remove('hide')
      }
      sessionStorage.setItem('showKupilka', 'true')
      sessionStorage.setItem('origin_url', window.location.href)
      axios.post('/local/api/agreementKupilka.php', { 'AGREE': 'Y' }).then((res) => {
      }).catch(err => console.log(err))
      setTimeout(() => {
        this.setKupilkaPopUpVisibilityLK(false, null)
      }, 1000)
    },
    getDaysInMonth (data) {
      const DATE = data.DATE
      const endPromoteDate = new Date(DATE)
      const currentDate = new Date(`${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`)
      const diffTime = endPromoteDate - currentDate
      this.promotionTimeLeft = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      // if (this.promotionTimeLeft >= 0 || data.TEXT !== '') {
      setTimeout(() => {
        this.setKupilkaPopUpVisibility(true, data)
      }, 1000)
    // }
    },
    getCheckedGame (data) {
      axios.post('/local/api/agreementKupilka.php', { 'AGREE': 'N' }).then((res) => {
      }).catch(err => console.log(err))
      setTimeout(() => {
        this.setKupilkaPopUpVisibilityLK(true, data)
      }, 1000)
    }
  },
  mounted () {
    this.eventHub.$emit('Loading', false)
    const visited = sessionStorage.getItem('visited')
    const visitedLk = sessionStorage.getItem('lk_visited')

    // let $this = this
    axios.get('/local/api/kupilkaPopup.php').then((res) => {
      if (res.data.SHOW === 'Y' && visited !== 'true') {
        /*
        setTimeout(function () {
          $this.setKupilkaPopUpVisibility(false, null)
        }, 16000) */
        this.getDaysInMonth(res.data)
      } else {
        axios.post('/local/api/kupilkaPersonal.php', { 'TITLE': 'Y' }).then((res) => {
          // console.log(res)
          if (res.data.SHOW === 'Y' && visitedLk !== 'true') {
            localStorage.removeItem('showK')
            if (window.location.pathname === '/personal/') {
              this.currentURL = window.location.pathname
            } else {
              this.currentURL = ''
            }
            this.getCheckedGame(res.data)
          }
        }).catch(err => console.log(err))
      }
    }).catch(err => console.log(err))
    document.addEventListener('DOMContentLoaded', () => {
      const firstKupilka = document.querySelector('.kupilka-popup__text-wrapper-shadow')
      // console.log(firstKupilka)
      if (!firstKupilka) {
        axios.post('/local/api/kupilkaPersonal.php', { 'TITLE': 'Y' }).then((res) => {
          // console.log(res)
          if (res.data.SHOW === 'Y' && visitedLk !== 'true') {
            if (window.location.pathname === '/personal/') {
              this.currentURL = window.location.pathname
            } else {
              this.currentURL = ''
            }
            this.getCheckedGame(res.data)
          }
        }).catch(err => console.log(err))
      }
    })
  }
}
