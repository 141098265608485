import axios from 'axios'
import MaskedInput from 'vue-masked-input'

export default {
  data () {
    return {
      userId: '',
      phoneAuth: '',
      haveCode: false,
      check_card: false,
      $create_new_user: false,
      formDataStart: {
        phone: '',
        code: '',
        card_number: '94820000'
      },
      loadingBtn: false,
      show: false,
      valid: true
    }
  },
  name: 'LoginAccount',
  props: {
    showClose: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MaskedInput
  },
  mounted () {
    let results = document.cookie.match(/phoneAuth=(.+?)(;|$)/)
    if (results !== null) {
      this.phoneAuth = results[1]
      if (this.phoneAuth) this.formDataStart.phone = this.phoneAuth
    }
  },
  methods: {
    submitStart () {
      this.loadingBtn = true
      axios.post('/local/api/smsAuthSend.php', {
        phone: this.formDataStart.phone
      }).then(() => {
        this.haveCode = true
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Вам на телефон отправлен код подтверждения!'
        })
        this.loadingBtn = false
        if (!this.phoneAuth) {
          document.cookie = 'phoneAuth=' + this.formDataStart.phone
          document.cookie = 'path=/'
          document.cookie = 'max-age=2629743'
        }
        if ('OTPCredential' in window) {
          const ac = new AbortController()
          navigator.credentials.get({
            otp: { transport: ['sms'] },
            signal: ac.signal
          }).then(otp => {
            let timerId = setInterval(() => {
              if (document.querySelector('input[autocomplete="one-time-code"]')) {
                clearInterval(timerId)
                const inputss = document.querySelector('#PHONE_CODE_AUTH')
                inputss.focus()
                const submitBtn = inputss.closest('.auth-form').querySelector('#codeBtn')
                if (submitBtn) {
                  submitBtn.addEventListener('click', e => {
                    ac.abort()
                  })
                }
                inputss.value = otp.code
                inputss.dispatchEvent(new Event('paste'))
                this.formDataStart.code = otp.code
              }
            }, 500)
          }).catch(err => {
            console.log(err)
            // alert(err)
          })
        }
      }).catch(e => {
        console.log(e.response)
        if (e.response.data.action === 'show_register') {
          this.eventHub.$emit('showRegistration', false)
          this.eventHub.$emit('ShowAuthFormHeader', {
            phone: this.formDataStart.phone
          })
        }
        if (e.response.data.action === 'check_card') {
          this.check_card = true
        }
        if (e.response.data.create_new_user === true) {
          this.create_new_user = true
        }
        let message = e.response.data.message
        if (e.response.data.type === 'html') {
          message = [
            {
              name: '',
              value: e.response.data.message
            }
          ]
        }
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: message
        })
        this.loadingBtn = false
      })
    },
    restoreByCard () {
      this.loadingBtn = true
      axios.post('/local/api/checkActiveCard.php', {
        card_number: this.formDataStart.card_number
      }).then(res => {
        console.log(res)
        let message = res.data.message
        if (res.data.type === 'html') {
          message = [
            {
              name: '',
              value: res.data.message
            }
          ]
        }
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: message
        })
        this.loadingBtn = false
      }).catch(e => {
        console.log(e)
        let message = e.response.data.message
        if (e.response.data.type === 'html') {
          message = [
            {
              name: '',
              value: e.response.data.message
            }
          ]
        }
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: message
        })
        this.loadingBtn = false
      })
    },
    submitCheckCode () {
      this.loadingBtn = true
      axios.post('/local/api/checkSmsAuthCode.php', {
        phone: this.formDataStart.phone,
        code: this.formDataStart.code
      }).then(res => {
        this.$refs.formLoginAccount.$el[0].value = ''
        this.loadingBtn = false
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Вы успешно вошли!'
        })
        setTimeout(() => {
          if (this.create_new_user === false) {
            window.location.reload()
          } else {
            window.location.href = '/personal/#lk'
          }
        }, 333)
      }).catch(e => {
        console.log(e.response)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
        this.loadingBtn = false
      })
    }
  }
}
