var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "section--left-nav text-left hidden-md-and-down",
      class: { _fixed: _vm.fixed },
    },
    [
      _c("div", { staticClass: "logo logo-test" }, [
        _c("a", { attrs: { href: _vm.parameters.LINK_LOGO } }, [
          _c("img", {
            staticClass: "header-logo",
            attrs: { src: _vm.parameters.IMG_LOGO, alt: "" },
          }),
        ]),
        _vm._v(" "),
        _vm.parameters.SHOW_TAGLINE === "Y"
          ? _c("span", {
              staticClass: "logo-text",
              domProps: { innerHTML: _vm._s(_vm.parameters.TEXT_TAGLINE) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("nav", [
        _c(
          "ul",
          { staticClass: "left-nav-with-icon" },
          _vm._l(_vm.menu, function (item, i) {
            return _c(
              "li",
              { key: i, class: { active: _vm.showSub == i } },
              [
                item.subMenu
                  ? _c(
                      "a",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.showSubNav($event, i, item.href)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "left-nav-icon" }, [
                          _c("img", { attrs: { src: item.icon, alt: "" } }),
                        ]),
                        _vm._v(
                          "\n          " + _vm._s(item.text) + "\n        "
                        ),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { href: item.href },
                      },
                      [
                        _c("div", { staticClass: "left-nav-icon" }, [
                          _c("img", { attrs: { src: item.icon, alt: "" } }),
                        ]),
                        _vm._v(
                          "\n          " + _vm._s(item.text) + "\n        "
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c("transition", { attrs: { name: "slide-fade" } }, [
                  item.subMenu && _vm.showSub == i
                    ? _c(
                        "div",
                        {
                          staticClass: "left-subnav",
                          style: { left: _vm.leftPositionSubNAv + "px" },
                        },
                        [
                          _c(
                            "ul",
                            _vm._l(item.subMenu, function (subItem) {
                              return _c("li", [
                                subItem.onlyUpdateMap
                                  ? _c(
                                      "a",
                                      {
                                        attrs: { href: subItem.href },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editMap(subItem)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(subItem.text))]
                                    )
                                  : _c("a", { attrs: { href: subItem.href } }, [
                                      _vm._v(_vm._s(subItem.text)),
                                    ]),
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "left-subnav-arrow",
                            style: { top: _vm.positionTopArrowSubNav + "px" },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "left-nav-other-link" },
          _vm._l(_vm.menuBottom, function (item) {
            return _c("li", [
              _c("a", { attrs: { href: item.href } }, [
                _vm._v(_vm._s(item.text)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }