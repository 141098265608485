/* eslint-disable */
import $ from 'jquery'

(function ($) {
  $.fn.extend({
    easyResponsiveTabs: function (options) {
      //Set the default values, use comma to separate the settings, example:
      var defaults = {
        type: 'default', //default, vertical, accordion;
        width: 'auto',
        fit: true,
        closed: false,
        activate: function(){}
      }
      //Variables
      var options = $.extend(defaults, options);
      var opt = options, jtype = opt.type, jfit = opt.fit, jwidth = opt.width, vtabs = 'vertical', accord = 'accordion';

      //Events
      $(this).bind('tabactivate', function(e, currentTab) {
        if(typeof options.activate === 'function') {
          options.activate.call(currentTab, e)
        }
      });

      //Main function
      this.each(function () {
        var $respTabs = $(this);
        var $respTabsList = $respTabs.find('ul.resp-tabs-list');
        $respTabs.find('ul.resp-tabs-list li').addClass('resp-tab-item');
        $respTabs.css({
          'display': 'block',
          'width': jwidth
        });

        $respTabs.find('.resp-tabs-container > div').addClass('resp-tab-content');
        jtab_options();
        //Properties Function
        function jtab_options() {
          if (jtype == vtabs) {
            $respTabs.addClass('resp-vtabs');
          }
          if (jfit == true) {
            $respTabs.css({ width: '100%', margin: '0px' });
          }
          if (jtype == accord) {
            $respTabs.addClass('resp-easy-accordion');
            $respTabs.find('.resp-tabs-list').css('display', 'none');
          }
        }

        //Assigning the h2 markup to accordion title
        var $tabItemh2;
        $respTabs.find('.resp-tab-content').before("<h2 class='resp-accordion' role='tab'></h2>");

        var itemCount = 0;
        $respTabs.find('.resp-accordion').each(function () {
          $tabItemh2 = $(this);
          var innertext = $respTabs.find('.resp-tab-item:eq(' + itemCount + ')').html();
          $respTabs.find('.resp-accordion:eq(' + itemCount + ')').append(innertext);
          $tabItemh2.attr('aria-controls', 'tab_item-' + (itemCount));
          itemCount++;
        });

        //Assigning the 'aria-controls' to Tab items
        var count = 0,
          $tabContent;
        $respTabs.find('.resp-tab-item').each(function () {
          let $tabItem = $(this);
          $tabItem.attr('aria-controls', 'tab_item-' + (count));
          $tabItem.attr('role', 'tab');

          //First active tab, keep closed if option = 'closed' or option is 'accordion' and the element is in accordion mode
          if(options.closed !== true && !(options.closed === 'accordion' && !$respTabsList.is(':visible')) && !(options.closed === 'tabs' && $respTabsList.is(':visible'))) {
            $respTabs.find('.resp-tab-item').first().addClass('resp-tab-active');
            $respTabs.find('.resp-accordion').first().addClass('resp-tab-active');
            $respTabs.find('.resp-tab-content').first().addClass('resp-tab-content-active').attr('style', 'display:block');
          }

          //Assigning the 'aria-labelledby' attr to tab-content
          var tabcount = 0;
          $respTabs.find('.resp-tab-content').each(function () {
            $tabContent = $(this);
            $tabContent.attr('aria-labelledby', 'tab_item-' + (tabcount));
            tabcount++;
          });
          count++;
        });

        //Tab Click action function
        $respTabs.find("[role=tab]").each(function () {
          var $currentTab = $(this);
          $currentTab.click(function () {

            var $tabAria = $currentTab.attr('aria-controls');

            if ($currentTab.hasClass('resp-accordion') && $currentTab.hasClass('resp-tab-active')) {
              $respTabs.find('.resp-tab-content-active').slideUp('', function () { $(this).addClass('resp-accordion-closed'); });
              $currentTab.removeClass('resp-tab-active');
              return false;
            }
            if (!$currentTab.hasClass('resp-tab-active') && $currentTab.hasClass('resp-accordion')) {
              $respTabs.find('.resp-tab-active').removeClass('resp-tab-active');
              $respTabs.find('.resp-tab-content-active').slideUp().removeClass('resp-tab-content-active resp-accordion-closed');
              $respTabs.find("[aria-controls=" + $tabAria + "]").addClass('resp-tab-active');

              $respTabs.find('.resp-tab-content[aria-labelledby = ' + $tabAria + ']').slideDown().addClass('resp-tab-content-active');
            } else {
              $respTabs.find('.resp-tab-active').removeClass('resp-tab-active');
              $respTabs.find('.resp-tab-content-active').removeAttr('style').removeClass('resp-tab-content-active').removeClass('resp-accordion-closed');
              $respTabs.find("[aria-controls=" + $tabAria + "]").addClass('resp-tab-active');
              $respTabs.find('.resp-tab-content[aria-labelledby = ' + $tabAria + ']').addClass('resp-tab-content-active').attr('style', 'display:block');
            }
            //Trigger tab activation event
            $currentTab.trigger('tabactivate', $currentTab);
          });
          //Window resize function
          $(window).resize(function () {
            $respTabs.find('.resp-accordion-closed').removeAttr('style');
          });
        });
      });
    }
  });

})($);

class Cookies {
  constructor () {

    this.$tplTabsNow = $(`<div class="v-overlay v-overlay--active"></div>
      <div class="parent-tabs">
        <div id="verticalTab">
          <div class="tabs-title">
          Настройки файлов cookie
          <!--<a href="#" class="tabs-close">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.2 16.375L0.625 14.8L6.925 8.5L0.625 2.2L2.2 0.625L8.5 6.925L14.8 0.625L16.375 2.2L10.075 8.5L16.375 14.8L14.8 16.375L8.5 10.075L2.2 16.375Z" fill="black" fill-opacity="0.25"/>
            </svg>
            </a>-->
          </div>
          <ul class="resp-tabs-list">
            <div class="image-block"><img src="/upload/kupilka-pop-up.png" /><div>
          </ul>
          <div class="resp-tabs-container first">
            <div style="display:block;">
              <p>Этот веб-сайт использует файлы cookie. При использовании данного сайта вы подтвержаете свое согласие на использование cookie-файлов в соответствии с нашей политикой использования файлов cookie.</p>
              <p><a href="/politikacookies/">Ознакомьтесь с Политикой использования файлов cookie</a></p>
            </div>
          </div>
          <div class="tabs-btns">
            <a href="#" id="cookiePolicyReject" class="btn--sosedi clean v-btn" style="margin-left: auto;">Отказаться</a>
            <a href="#" id="cookiePolicyAccept" class="btn--sosedi orange v-btn">Принять все</a>
          </div>
        </div>
      </div>
    `);


    this.$tplTabs = $(`<div class="v-overlay v-overlay--active"></div>
        <div class="parent-tabs">
            <div id="verticalTab">
            <div class="tabs-title">
              Настройки файлов cookie
              <!--<a href="#" class="tabs-close">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.2 16.375L0.625 14.8L6.925 8.5L0.625 2.2L2.2 0.625L8.5 6.925L14.8 0.625L16.375 2.2L10.075 8.5L16.375 14.8L14.8 16.375L8.5 10.075L2.2 16.375Z" fill="black" fill-opacity="0.25"/>
                </svg>
              </a>-->
            </div>
            <ul class="resp-tabs-list">
                <!--<li>Конфиденциальность пользователя</li>-->
                <li>Файлы cookie, необходимые для правильной работы веб-сайта</li>
                <li>Функциональные файлы cookie</li>
                <li>Статистические файлы cookie</li>
            </ul>
            <div class="resp-tabs-container">
                <!--<div>
                    <p class="tab-content-title">Конфиденциальность пользователя</p>
                    <p>Этот веб-сайт использует файлы cookie. Мы используем информацию, сохраненную с помощью файлов 
                    cookie, в том числе в рекламных и статистических целях, а также для адаптации нашего веб-сайта к 
                    индивидуальным потребностям пользователей. Использование нашего веб-сайта без изменения настроек 
                    файлов cookie означает, что они будут храниться в памяти устройства. Используя веб-сайт, вы даете 
                    согласие на использование файлов cookie в соответствии с текущими настройками браузера.</p>
                    <a href="/politikacookies/">Ознакомьтесь с Политикой использования файлов cookie</a>
                </div>-->
                <div>
                    <p class="tab-content-title">Файлы cookie, необходимые для правильной работы веб-сайта</p>
                    <p class="only-active">Всегда активный</p>
                    <p>Являются необходимыми для бесперебойного и надежного функционирования сайта. Отключение данного 
                    типа файлов cookie может привести к ограничению в доступе к определенным функциональным возможностям 
                    Сайта. Хранятся не более 1 года.</p>
                </div>
                <div>
                    <p class="tab-content-title">Функциональные файлы cookie</p>
                    <label class="switch">
                        <input type="checkbox" id="functionCookie" checked />
                        <span class="slider round"></span>
                    </label>
                    <p>Позволяют обеспечить индивидуальный опыт использования сайта, сохраняются в памяти устройства в 
                    течение определенного периода времени и отвечают за личные предпочтения Пользователя, установленные 
                    в браузере. Хранятся не более 1 года.</p>
                </div>
                <div>
                    <p class="tab-content-title">Статические файлы cookie</p>
                    <label class="switch">
                        <input type="checkbox" id="staticCookie" checked />
                        <span class="slider round"></span>
                    </label>
                    <p>Нужны для отслеживания страниц и разделов Сайта, посещаемых Пользователем, а также выявлении 
                    возможных ошибок в работе Сайта. Информация, собираемая данным видом файлов cookie, является анонимной 
                    и необходима для эффективного поддержания работы и обновления Сайта. Хранятся не более 1 года</p>
                </div>
            </div>
            <div class="tabs-btns">
                <!--<a href="#" id="cookiePolicySave" class="btn--sosedi orange v-btn v-btn-outline">Сохранить</a>
                <a href="#" id="cookiePolicyRejectTabs" class="btn--sosedi clean v-btn" style="margin-left: auto;">Отклонить все</a>-->
                <a href="#" id="cookiePolicySave" class="btn--sosedi orange v-btn v-btn-outline" style="margin-left: auto;">Сохранить</a>
                <a href="#" id="cookiePolicyAcceptTabs" class="btn--sosedi orange v-btn">Принять все</a>
            </div>
        </div></div>`)

    this.$tpl = $(`
      <div class="cookie-bar">
        <div class="container">
            <div class="cookie-bar-layout">
              <div class="cookie-bar-text">
                Этот веб-сайт использует cookie-файлы. <br>
                При использовании данного сайта вы подтверждаете свое согласие на использование cookie-файлов в&nbsp;соответствии с нашей <a href="/politikacookies/" target="_blank" rel="nofollow">Политикой использования файлов Cookie</a>.
              </div>
              <span id="cookiePolicyAccept" class="cookie-bar-ok" title="Принять">Принять</span>
              <span id="cookiePolicyReject" class="cookie-bar-reject" title="Отказаться">Отказаться</span>
            </div>
          </div>
      </div>
    `)
  }

  init () {
    $(document).ready(() => {
      this.createCookies()
      this.cookiePolicyAccept()

      let selff = this;
      setTimeout(() => {
        $('.footer-hrefs a').last().css('cursor','pointer');
        $('.footer-hrefs a').last().on('click', function () {
          selff.createCookiesReturn()
          //selff.cookiePolicyAccept()
        });
      }, 2000)
    })
  }

  getCookie (name) {
    let matches = document.cookie.match(new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ))

    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  setCookie (name, value, options = {}) {
    options = {
      path: '/',
      ...options
    }

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString()
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey
      let optionValue = options[optionKey]
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue
      }
    }

    document.cookie = updatedCookie
  }

  createCookiesReturn () {
    if (this.getCookie('cookiepolicyaccept')) {
      // this.$tpl.appendTo($('body'))
      let self = this;

      self.$tplTabsNow.appendTo($('body'))
      console.log(self.$tplTabsNow.find('#verticalTab'));
      console.log($('#verticalTab'));
      self.$tplTabsNow.find('#verticalTab').easyResponsiveTabs({
        type: 'vertical',
        fit: true
      })

      $('#cookiePolicyReject').on('click', function () {
        self.hideFirstCookiePopup()

        self.$tplTabsNow.remove()
        self.$tplTabs.appendTo($('body'))
        
        $('#verticalTab').easyResponsiveTabs({
          type: 'vertical',
          fit: true
        })

        self.cookiePolicyReject()
        self.cookiePolicySave()
        $('#cookiePolicyAcceptTabs').on('click', function () {
          self.setCookie('cookiepolicyaccept', 'true')
          self.hideSecondCookiePopup()
          $.ajax({
            url: '/',
            type: "POST",
            success: function(data) {
              $('body').html($(data))
            }
          })
        })

        // $('.tabs-close').on('click', function (e) {
          // e.preventDefault()
          // self.hideSecondCookiePopup()
        // })
      });
    }
  }

  createCookies () {
    if (!this.getCookie('cookiepolicyaccept')) {
      // this.$tpl.appendTo($('body'))
      let self = this;

      self.$tplTabsNow.appendTo($('body'))
      $('#verticalTab').easyResponsiveTabs({
        type: 'vertical',
        fit: true
      })

      $('#cookiePolicyReject').on('click', function () {
        self.hideFirstCookiePopup()

        self.$tplTabsNow.remove()
        self.$tplTabs.appendTo($('body'))
        
        $('#verticalTab').easyResponsiveTabs({
          type: 'vertical',
          fit: true
        })

        self.cookiePolicyReject()
        self.cookiePolicySave()
        $('#cookiePolicyAcceptTabs').on('click', function () {
          self.setCookie('cookiepolicyaccept', 'true')
          self.hideSecondCookiePopup()
          $.ajax({
            url: window.location.href,
            type: "POST",
            success: function(data) {
              $('body').html($(data))
            }
          })
        })

        // $('.tabs-close').on('click', function (e) {
          // e.preventDefault()
          // self.hideSecondCookiePopup()
        // })
      });
    }
  }

  cookiePolicyAccept () {
    const self = this
    $('#cookiePolicyAccept').on('click', function () {
      self.setCookie('cookiepolicyaccept', 'true')
      self.hideFirstFirstCookiePopup()
      self.hideFirstCookiePopup()
      $.ajax({
        url: window.location.href,
        type: "POST",
        success: function(data) {
          $('body').html($(data))
        }
      })
    })
  }

  cookiePolicyReject () {
    const self = this
    $('#cookiePolicyRejectTabs').on('click', function () {
      self.setCookie('cookiepolicyaccept', 'false')
      self.hideSecondCookiePopup()
    })
  }

  cookiePolicySave () {
    console.log('cookiePolicySave1')
    const self = this
    $('#cookiePolicySave').on('click', function (e) {
      e.preventDefault()
      // значение будет выбрано в соответствии с чекбоксами
      console.log('cookiePolicySave2')
      let value = 'false';
      if ($('#functionCookie').prop('checked')) {
        value = 'func';
      }

      if ($('#staticCookie').prop('checked') && value === 'func') {
        value = 'true'
      } else if ($('#staticCookie').prop('checked')) {
        value = 'static'
      }

      self.setCookie('cookiepolicyaccept', value)
      self.hideSecondCookiePopup()

      $.ajax({
        url: window.location.href,
        type: "POST",
        success: function(data) {
          $('body').html($(data))
        }
      })
    })
  }

  hideFirstFirstCookiePopup () {
    let self = this
    this.$tplTabsNow.fadeOut(300, function () {
      setTimeout(() => {
        self.$tpl.remove()
      }, 500)
    })
  }
  hideFirstCookiePopup () {
    let self = this
    this.$tpl.fadeOut(300, function () {
      setTimeout(() => {
        self.$tpl.remove()
      }, 500)
    })
  }

  hideSecondCookiePopup () {
    let self = this
    this.$tplTabs.fadeOut(300, function () {
      setTimeout(() => {
        self.$tplTabs.remove()
      }, 500)
    })
  }
}

export const cookies = new Cookies()
