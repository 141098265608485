import BaseComponent from '../../../BaseComponent'

export default {
  name: 'LeftNav',
  extends: BaseComponent,
  props: ['menu', 'menuBottom', 'parameters'],
  data () {
    return {
      showSub: null,
      positionTopArrowSubNav: 0,
      leftPositionSubNAv: 0,
      fixed: null,
      headerContainer: document.querySelector('.header-container'),
      hasfon: document.querySelector('#app.hasfon')
    }
  },
  methods: {
    editMap (route) {
      this.eventHub.$emit('updateMap', route.href)
    },
    onResize () {
      let widthContainer = document.querySelector('.header-container').offsetWidth
      let widthMainContent = document.querySelector('.main-fon').offsetWidth
      this.leftPositionSubNAv = widthContainer - widthMainContent - this.deltaWidth()
    },
    deltaWidth () {
      if (window.innerWidth < 1401) return 59
      if (window.innerWidth < 1501) return 64
      return 91
    },
    showSubNav (event, i, href, type) {
      if (this.showSub === i) return
      this.showSub = i
      if (this.hasfon) {
        this.positionTopArrowSubNav = event.target.offsetTop + event.target.offsetHeight / 2
      } else {
        this.positionTopArrowSubNav = event.y - 123
      }
    },
    hideSubNav () {
      let _this = this
      document.body.addEventListener('click', function (event) {
        _this.showSub = null
      }, true)
    },
    fixedHandler () {
      if (this.headerContainer === null) {
        this.headerContainer = document.querySelector('.header-container')
      }
      if (this.headerContainer) {
        const top = this.headerContainer.getBoundingClientRect().top
        if (top <= 0) {
          this.fixed = true
        } else {
          this.fixed = false
        }
      }
    }
  },
  mounted () {
    this.hideSubNav()
    this.onResize()
    window.addEventListener('resize', this.onResize, {
      passive: true
    })
    if (this.hasfon) {
      window.addEventListener('scroll', this.fixedHandler, {
        passive: true
      })
      this.fixedHandler()
    }
  }
}
