import axios from 'axios'
import MaskedInput from 'vue-masked-input'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      phone: '',
      checkCode: '',
      changePass: '',
      save: '',
      showPass1: false,
      showPass2: false,
      dataPicker: false,
      show: false,
      valid: true,
      formData: {
        email: '',
        phone: '',
        password: '',
        passwordRepeat: ''
      },
      rules: {
        required: value => !!value || 'Обязательное поле.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неправильный e-mail.'
        },
        text: v => (v && v.length <= 10) || 'Длинна текста должна быть не менее 10 символов'
      }
    }
  },
  components: {
    MaskedInput
  },
  mounted () {
    this.eventHub.$on('showForgotPass', data => {
      this.show = data.show
      if (data.phone) {
        this.phone = data.phone
      }
    })
  },
  methods: {
    submit () {
      let textGood = 'На ваш номер телефона выслан код подтверждения'
      let urlApi = '/local/api/forgotPassword_sms.php'
      let formData = {
        phone: this.phone
      }

      if (this.checkCode) {
        textGood = 'Номер телефона подтвержден'
        urlApi = '/local/api/checkSmsCode.php'
        formData = {
          code: this.formData.code,
          phone: this.phone
        }
        this.save = false
      } else if (this.changePass) {
        textGood = 'Пароль успешно изменен!'
        urlApi = '/local/api/changePassword.php'
        formData = {
          login: this.phone,
          password: this.formData.password,
          passwordRepeat: this.formData.passwordRepeat
        }
        this.save = true
      }

      axios.post(urlApi, formData).then(res => {
        console.log(res.status)
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: textGood
        })
        if (this.checkCode) {
          this.checkCode = false
          this.changePass = true
        }
        if (this.save) {
          this.show = false

          if (window.location.href.indexOf('/personal/') === -1) {
            this.eventHub.$emit('ShowAuthFormHeader', {
              phone: this.phone
            })
          } else {
            this.eventHub.$emit('showAuthForm')
          }
        }

        if (!this.changePass && !this.checkCode) {
          this.checkCode = true
        }
      }).catch(e => {
        console.log(e.response)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
      })
    }
  }
}
