var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.step === 1
        ? _c(
            "v-card",
            [
              _c("v-card-text", [
                _c("p", { staticClass: "text-center" }, [
                  _vm._v(
                    "Прежде чем начать регистрацию, ознакомьтесь с нашей политикой обработки персональных данных и дайте ваши согласия."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn--sosedi orange big",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.step = 2
                        },
                      },
                    },
                    [_vm._v("\n        Ознакомиться и дать согласия\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.step === 2
        ? _c(
            "v-card",
            {
              staticClass: "registration position-relative",
              class: { showPolicy: _vm.showPolicy },
            },
            [
              _vm.showClose
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-close",
                      attrs: { flat: "", icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.eventHub.$emit("showRegistration", false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-layout", [
                _c("div", {
                  staticClass: "title-section mb-3",
                  domProps: { textContent: _vm._s(_vm.registrationTitle) },
                }),
              ]),
              _vm._v(" "),
              _vm.showPolicy
                ? _c("v-layout", { attrs: { column: true, "mh-resp": "" } }, [
                    _c("div", {
                      staticClass: "text policy_text",
                      domProps: { innerHTML: _vm._s(_vm.policy.DETAIL_TEXT) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text extends_text" }, [
                      _vm._v(
                        'На основании предоставленной выше информации для субъектов персональных данных я ДАЮ СОГЛАСИЕ ООО\n      "ЛибретикГрупп", являющемуся Оператором, на обработку моих персональных данных в следующих целях:\n    '
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "checkbox-list" },
                      _vm._l(_vm.checkboxes, function (checkbox, i) {
                        return _c(
                          "v-flex",
                          { key: "check_" + i, attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              staticClass: "style--sosedi-checkbox",
                              attrs: {
                                color: "#007DC6",
                                required: checkbox.required,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            checkbox.required
                                              ? _c(
                                                  "span",
                                                  { staticClass: "required" },
                                                  [_vm._v("*")]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(checkbox.label) +
                                                "\n              "
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "hint",
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("?")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(checkbox.hint) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: checkbox.value,
                                callback: function ($$v) {
                                  _vm.$set(checkbox, "value", $$v)
                                },
                                expression: "checkbox.value",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "required_data" }, [
                      _vm._v(
                        "Поле, отмеченное звездочкой, обязательно для заполнения. Без него Вы не сможете\n      продолжить регистрацию.\n    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btns" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn--sosedi orange big",
                            attrs: { disabled: !_vm.checkboxes.kupilka.value },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.checkCheckboxes.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("\n        Сохранить\n      ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm.startReg
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "section-form",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              _vm.haveCode
                                ? _vm.submitCheckCode()
                                : _vm.submitStart()
                            },
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "", sm3: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-name-field d-flex align-center",
                                  },
                                  [_vm._v("Ваш телефон")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "" } },
                                [
                                  _c("masked-input", {
                                    staticClass: "form-input-mask",
                                    attrs: {
                                      mask: "\\+\\375 (11) 1111111",
                                      placeholder: "Ваш номер телефона",
                                      type: "tel",
                                      id: "phone",
                                    },
                                    model: {
                                      value: _vm.formDataStart.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDataStart,
                                          "phone",
                                          $$v
                                        )
                                      },
                                      expression: "formDataStart.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: { xs12: "", sm4: "" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.haveCode
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm3: "" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-name-field d-flex align-center",
                                        },
                                        [_vm._v("Код подтверждения")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm5: "" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "form-input",
                                        attrs: {
                                          mask: "######",
                                          loading: "",
                                          "single-line": "",
                                          solo: "",
                                          height: "33",
                                        },
                                        model: {
                                          value: _vm.formDataStart.code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formDataStart,
                                              "code",
                                              $$v
                                            )
                                          },
                                          expression: "formDataStart.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-flex", {
                                    staticClass: "text-info-field",
                                    attrs: {
                                      "hidden-xs-only": "",
                                      xs12: "",
                                      sm4: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "", sm3: "" } }),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm9: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn--sosedi orange big",
                                      attrs: {
                                        loading: _vm.loadingBtn,
                                        disabled: _vm.loadingBtn,
                                        type: "submit",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.haveCode
                                              ? "Продолжить"
                                              : "Получить код"
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.haveCode
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "btn--sosedi big outline",
                                          attrs: {
                                            loading: _vm.loadingBtn,
                                            disabled: _vm.loadingBtn,
                                            color: "#f36f21",
                                            outline: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.submitStart()
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [_vm._v("repeat")]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Получить код повторно"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.checkCard
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "section-form",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submitCheckCard()
                            },
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Номер бонусной карты:")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("masked-input", {
                                    staticClass: "form-input-mask",
                                    attrs: {
                                      mask: "9482 0000 1111 111",
                                      autocomplete: "off",
                                      id: "card",
                                    },
                                    model: {
                                      value: _vm.formData.cardNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "cardNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formData.cardNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-info-field",
                                  attrs: {
                                    "hidden-xs-only": "",
                                    xs12: "",
                                    sm4: "",
                                    md6: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          15 символов на оборотной стороне карты.\n        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", {
                                attrs: { xs12: "", sm3: "", md2: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn--sosedi orange big",
                                      attrs: {
                                        loading: _vm.loadingBtn,
                                        disabled: _vm.loadingBtn,
                                        type: "submit",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            Проверить номер карты\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-layout",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "section-form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submitFinish()
                            },
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Номер бонусной карты")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      "validate-on-blur": "",
                                      "single-line": "",
                                      solo: "",
                                      height: "33",
                                      disabled: true,
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.card_number,
                                      ],
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.formData.cardNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "cardNumber",
                                          $$v
                                        )
                                      },
                                      expression: "formData.cardNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: {
                                  "hidden-xs-only": "",
                                  xs12: "",
                                  sm4: "",
                                  md6: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Фамилия")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      "validate-on-blur": "",
                                      "single-line": "",
                                      solo: "",
                                      height: "33",
                                      disabled:
                                        !_vm.checkboxes.marketing.value &&
                                        !_vm.checkboxes.personal.value &&
                                        !_vm.checkboxes.advertising.value,
                                      rules: [
                                        _vm.rules.requiredCheckboxes,
                                        _vm.rules.last_name,
                                      ],
                                      autocomplete: "off",
                                      hint:
                                        !_vm.checkboxes.marketing.value &&
                                        !_vm.checkboxes.personal.value &&
                                        !_vm.checkboxes.advertising.value
                                          ? "Вы не дали согласие на заполнение этого поля"
                                          : "",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.formData.last_name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "last_name", $$v)
                                      },
                                      expression: "formData.last_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: {
                                  "hidden-xs-only": "",
                                  xs12: "",
                                  sm4: "",
                                  md6: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Имя")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      "validate-on-blur": "",
                                      "single-line": "",
                                      solo: "",
                                      height: "33",
                                      disabled:
                                        !_vm.checkboxes.marketing.value &&
                                        !_vm.checkboxes.personal.value &&
                                        !_vm.checkboxes.advertising.value,
                                      rules: [
                                        _vm.rules.requiredCheckboxes,
                                        _vm.rules.name,
                                      ],
                                      autocomplete: "off",
                                      hint:
                                        !_vm.checkboxes.marketing.value &&
                                        !_vm.checkboxes.personal.value &&
                                        !_vm.checkboxes.advertising.value
                                          ? "Вы не дали согласие на заполнение этого поля"
                                          : "",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.formData.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "name", $$v)
                                      },
                                      expression: "formData.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: {
                                  "hidden-xs-only": "",
                                  xs12: "",
                                  sm4: "",
                                  md6: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Отчество")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      "validate-on-blur": "",
                                      "single-line": "",
                                      solo: "",
                                      height: "33",
                                      disabled:
                                        !_vm.checkboxes.marketing.value &&
                                        !_vm.checkboxes.personal.value &&
                                        !_vm.checkboxes.advertising.value,
                                      rules: [
                                        _vm.rules.requiredCheckboxes,
                                        _vm.rules.second_name,
                                      ],
                                      autocomplete: "off",
                                      hint:
                                        !_vm.checkboxes.marketing.value &&
                                        !_vm.checkboxes.personal.value &&
                                        !_vm.checkboxes.advertising.value
                                          ? "Вы не дали согласие на заполнение этого поля"
                                          : "",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.formData.second_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "second_name",
                                          $$v
                                        )
                                      },
                                      expression: "formData.second_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: {
                                  "hidden-xs-only": "",
                                  xs12: "",
                                  sm4: "",
                                  md6: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Телефон (Логин)")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      "validate-on-blur": "",
                                      "single-line": "",
                                      solo: "",
                                      height: "33",
                                      disabled: true,
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.formDataStart.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDataStart,
                                          "phone",
                                          $$v
                                        )
                                      },
                                      expression: "formDataStart.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: {
                                  "hidden-xs-only": "",
                                  xs12: "",
                                  sm4: "",
                                  md6: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Email")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "form-input",
                                    attrs: {
                                      "validate-on-blur": "",
                                      type: "email",
                                      "single-line": "",
                                      solo: "",
                                      height: "33",
                                      disabled: !_vm.checkboxes.personal.value,
                                      rules: [_vm.rules.email],
                                      hint: !_vm.checkboxes.personal.value
                                        ? "Вы не дали согласие на заполнение этого поля"
                                        : "",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.formData.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "email", $$v)
                                      },
                                      expression: "formData.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-info-field",
                                  attrs: {
                                    "hidden-xs-only": "",
                                    xs12: "",
                                    sm4: "",
                                    md6: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          На указанную почту будет приходить подтверждения изменений и рассылка новостей\n        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm3: "",
                                    md2: "",
                                    "pr-2": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Дата Вашего рождения:")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menupicker",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        lazy: "",
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "290px",
                                        disabled:
                                          !_vm.checkboxes.marketing.value,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "form-input mobile-small",
                                                    attrs: {
                                                      slot: "activator",
                                                      "append-icon": "event",
                                                      readonly: "",
                                                      "single-line": "",
                                                      solo: "",
                                                      height: "33",
                                                      rules: [
                                                        _vm.rules
                                                          .requiredMarketing,
                                                      ],
                                                      disabled:
                                                        !_vm.checkboxes
                                                          .marketing.value,
                                                      hint: !_vm.checkboxes
                                                        .marketing.value
                                                        ? "Вы не дали согласие на заполнение этого поля"
                                                        : "",
                                                      "persistent-hint": "",
                                                    },
                                                    slot: "activator",
                                                    model: {
                                                      value:
                                                        _vm.formData.birthday,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.formData,
                                                          "birthday",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "formData.birthday",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.dataPicker,
                                        callback: function ($$v) {
                                          _vm.dataPicker = $$v
                                        },
                                        expression: "dataPicker",
                                      },
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-date-picker", {
                                        ref: "picker",
                                        attrs: {
                                          max: _vm.birthdayMax,
                                          min: "1920-01-01",
                                          locale: "ru",
                                          "header-color": "#017dc6",
                                          color: "#017dc6",
                                          disabled:
                                            !_vm.checkboxes.marketing.value,
                                        },
                                        on: { change: _vm.saveBirthday },
                                        model: {
                                          value: _vm.formData.birthday,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "birthday",
                                              $$v
                                            )
                                          },
                                          expression: "formData.birthday",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-info-field",
                                  attrs: {
                                    "hidden-xs-only": "",
                                    xs12: "",
                                    sm4: "",
                                    md6: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          Чтобы знать, когда дарить Вам подарочные бонусы на День рождения.\n        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm3: "", md2: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-name-field d-flex align-center",
                                    },
                                    [_vm._v("Пол")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", md4: "" } },
                                [
                                  _c("v-select", {
                                    staticClass: "form-input",
                                    attrs: {
                                      items: _vm.genderList,
                                      "item-value": "value",
                                      "item-text": "title",
                                      outline: "",
                                      "append-icon": "unfold_more",
                                      height: "44",
                                      solo: "",
                                      disabled: !_vm.checkboxes.marketing.value,
                                      rules: [
                                        _vm.rules.requiredMarketingSelect,
                                      ],
                                      hint: !_vm.checkboxes.marketing.value
                                        ? "Вы не дали согласие на заполнение этого поля"
                                        : "",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.formData.gender,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "gender", $$v)
                                      },
                                      expression: "formData.gender",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", {
                                staticClass: "text-info-field",
                                attrs: {
                                  "hidden-xs-only": "",
                                  xs12: "",
                                  sm4: "",
                                  md6: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "mt-1": "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "", mh550: "" } }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("Обработка персональных данных"),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "policy",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.policy.DETAIL_TEXT),
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "required-text" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "checkbox-list" },
                                  _vm._l(
                                    _vm.checkboxes,
                                    function (checkbox, i) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: "check2_" + i,
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass:
                                              "style--sosedi-checkbox",
                                            attrs: {
                                              color: "#007DC6",
                                              required: checkbox.required,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        [
                                                          checkbox.required
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "required",
                                                                },
                                                                [_vm._v("*")]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                checkbox.label
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "hint",
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "?"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    checkbox.hint
                                                                  ) +
                                                                  "\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: checkbox.value,
                                              callback: function ($$v) {
                                                _vm.$set(checkbox, "value", $$v)
                                              },
                                              expression: "checkbox.value",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "mt-5": "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "flex-md-nowrap": "",
                                        "align-center": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn--sosedi orange big",
                                          attrs: {
                                            loading: _vm.loadingBtn,
                                            disabled:
                                              _vm.loadingBtn ||
                                              !_vm.checkboxes.kupilka.value,
                                            type: "submit",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Зарегистрироваться\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }