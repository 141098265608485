import { render, staticRenderFns } from "./component.vue?vue&type=template&id=91d9069e&scoped=true&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "./styles.scss?vue&type=style&index=0&id=91d9069e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91d9069e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git project\\dev.sosedi.by\\sosedisite\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('91d9069e')) {
      api.createRecord('91d9069e', component.options)
    } else {
      api.reload('91d9069e', component.options)
    }
    module.hot.accept("./component.vue?vue&type=template&id=91d9069e&scoped=true&", function () {
      api.rerender('91d9069e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "local/assets/source/vue/components/navs/LeftNav/component.vue"
export default component.exports