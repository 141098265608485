export default {
  data () {
    return {
      show: false
    }
  },
  name: 'PopupMobileReg',
  props: {
    showClosePopup: {
      type: Boolean,
      default: true
    },
    popupData: {
      type: Object
    }
  },
  mounted () {
    this.showPopup()
  // this.eventHub.$on('Loading', (is) => {
  // is ? this.open() : this.close()
  // })
  },
  methods: {
    showPopup () {
      var popUp = document.querySelector('.app-pop-up')
      if (popUp) {
        setTimeout(function () {
          popUp.classList.add('open')
        }, 150)
      }
    },
    open () {
      this.show = true
    },
    close () {
      setTimeout(() => {
        this.show = false
      }, 300)
    }
  }
}
