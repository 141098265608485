import svg4everybody from 'svg4everybody'
import 'slick-carousel'
import { triggers } from './Triggers'
import { cookies } from './Cookies'

class Main {
  initModules () {
    svg4everybody()
    triggers.init()
    cookies.init()
  }
}

export let main = new Main()
