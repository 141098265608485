import axios from 'axios'
import MaskedInput from 'vue-masked-input'

export default {
  data () {
    return {
      userId: '',
      showPolicy: true,
      step: 1,
      startReg: false,
      haveCode: false,
      registrationTitle: 'Обработка персональных данных',
      checkboxes: {
        kupilka: {
          value: false,
          required: true,
          label: 'Регистрация в бонусной программе Купилка',
          hint: 'Номер бонусной карты, Номер мобильного телефона, IP-адрес'
        },
        marketing: {
          value: false,
          required: false,
          label: 'Проведение маркетинговых исследований для предоставления дополнительных преимуществ в бонусной программе "Купилка" и повышения качества обслуживания',
          hint: 'Номер бонусной карты, Номер мобильного телефона, IP-адрес, Фамилия, Имя, Отчество, Дата рождения, Пол'
        },
        personal: {
          value: false,
          required: false,
          label: 'Предоставление персонализированной информации новостного и рекламного характера, посредством электронной почты, Viber, СМС, Push',
          hint: 'Номер бонусной карты, Номер мобильного телефона, IP-адрес, Фамилия, Имя, Отчество, Email'
        },
        advertising: {
          value: false,
          required: false,
          label: 'Участие в рекламных играх',
          hint: 'Номер бонусной карты, Номер мобильного телефона, IP-адрес, Фамилия, Имя, Отчество'
        }
      },
      formDataStart: {
        phone: '',
        code: ''
        // agreement: false
      },
      checkCard: false,
      loadingBtn: false,
      showPass1: false,
      showPass2: false,
      dataPicker: false,
      show: false,
      valid: true,
      address: '',
      bithday: new Date().toISOString().substr(0, 10),
      birthdayMax: new Date(new Date().setMonth(new Date().getMonth() - 14 * 12)).toISOString().substr(0, 10),
      formData: {
        email: '',
        cardNumber: '',
        birthday: '',
        // password: '',
        // passwordRepeat: '',
        // fio: '',
        name: '',
        second_name: '',
        last_name: '',
        // phoneHome: '',
        // city: '',
        // checkEmail: true,
        checkSms: false,
        checkAgry: null,
        gender: 1
        // address: '',
      },
      dataHaveInLoya: false,
      genderList: [
        {
          title: 'Мужской',
          value: 1
        },
        {
          title: 'Женский',
          value: 0
        }
      ],
      rules: {
        required: value => !!value || 'Обязательное поле.',
        email: value => {
          // if (this.checkboxes.personal.value === false) {
          // return false
          // }
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Неправильный e-mail.'
          }
        },
        text: v => (v && v.length >= 10) || 'Длинна текста должна быть не менее 10 символов',
        requiredMarketing: v => {
          if (this.checkboxes.marketing.value === false) {
            return false
          } else {
            return (v && v.length > 0) || 'Обязательное поле.'
          }
        },
        name: v => {
          if (this.checkboxes.marketing.value === false && this.checkboxes.personal.value === false && this.checkboxes.advertising.value === false) {
            return false
          }
          return (v && v.length >= 2) || 'Имя должно быть не менее 2 символов'
        },
        second_name: v => {
          if (this.checkboxes.marketing.value === false && this.checkboxes.personal.value === false && this.checkboxes.advertising.value === false) {
            return false
          }
          return (v && v.length >= 2) || 'Отчество должно быть не менее 2 символов'
        },
        last_name: v => {
          if (this.checkboxes.marketing.value === false && this.checkboxes.personal.value === false && this.checkboxes.advertising.value === false) {
            return false
          }
          return (v && v.length >= 2) || 'Фамилия должна быть не менее 2 символов'
        },
        card_number: value => {
          return value.replace(/\s+/g, '').length === 15 || 'Длина номера бонусной карты должна быть 15 символов'
        },
        requiredCheckboxes: value => {
          return this.checkboxes.marketing.value === true || this.checkboxes.personal.value === true || this.checkboxes.advertising.value === true
        },
        requiredMarketingSelect: value => {
          if (this.checkboxes.marketing.value === true) {
            return (value === 1 || value === 0) || 'Обязательное поле.'
          } else {
            return false
          }
        }
      }
    }
  },
  name: 'Registration',
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    policy: {
      type: Object
    }
  },
  mounted () {
    console.log(this.policy)
    console.log(this.policy)
  },
  watch: {
    dataPicker (val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  components: {
    MaskedInput
  },
  methods: {
    checkCheckboxes () {
      if (this.checkboxes.kupilka.value) {
        this.showPolicy = false
        this.startReg = true
        this.registrationTitle = 'Регистрация'
      }
    },
    saveBirthday (date) {
      this.$refs.menupicker.save(date)
    },
    submitStart () {
      this.loadingBtn = true
      axios.post('/local/api/smsSend.php', {
        phone: this.formDataStart.phone
        // agreement: this.formDataStart.agreement
      }).then(res => {
        this.haveCode = true
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Вам на телефон отправлен код подтверждения!'
        })
        this.loadingBtn = false
      }).catch(e => {
        console.log(e.response)
        if (e.response.data.userExist) {
          this.eventHub.$emit('showRegistration', false)
          this.eventHub.$emit('ShowAuthFormHeader', {
            phone: this.formDataStart.phone
            // agreement: this.formDataStart.agreement
          })
        }
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
        this.loadingBtn = false
      })
    },
    submitCheckCode () {
      this.loadingBtn = true
      axios.post('/local/api/checkSmsCode.php', {
        phone: this.formDataStart.phone,
        code: this.formDataStart.code
      }).then(res => {
        this.startReg = false
        this.checkCard = true
        this.$refs.form.$el[0].value = ''
        this.eventHub.$emit('ShowAlertMessage', {
          success: true,
          message: 'Вы успешно прошли первый этап регистрации!'
        })
        this.loadingBtn = false
      }).catch(e => {
        console.log(e.response)
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
        this.loadingBtn = false
      })
    },
    submitCheckCard () {
      axios.post('/local/api/checkCard.php', {
        card: this.formData.cardNumber,
        phone: this.formDataStart.phone
        // agreement: this.formDataStart.agreement
      }).then(res => {
        this.checkCard = false
      }).catch(e => {
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
      })
    },
    submitFinish () {
      this.loadingBtn = true
      if (!this.$refs.form.validate()) {
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: 'Вы не заполнили все обязательные поля.'
        })
        this.loadingBtn = false
        return
      }
      const dataSubmit = {
        cardNumber: this.formData.cardNumber,
        phone: this.formDataStart.phone,
        checkSms: this.formData.checkSms, // вроде же тут всегда false
        // checkAgry: this.formData.checkAgry
        // checkAgree: this.checkboxes.advertising.value
        policies: {
          kupilka: this.checkboxes.kupilka.value,
          marketing: this.checkboxes.marketing.value,
          personal: this.checkboxes.personal.value,
          advertising: this.checkboxes.advertising.value
        }
      }
      if (this.checkboxes.marketing.value || this.checkboxes.personal.value || this.checkboxes.advertising.value) {
        dataSubmit.name = this.formData.name
        dataSubmit.second_name = this.formData.second_name
        dataSubmit.last_name = this.formData.last_name
      }
      if (this.checkboxes.marketing.value) {
        dataSubmit.birthday = this.formData.birthday
        dataSubmit.gender = this.formData.gender
      }
      if (this.checkboxes.personal.value) {
        dataSubmit.email = this.formData.email
      }

      axios.post('/local/api/registrationPerson.php', dataSubmit).then(res => {
        window.sessionStorage.checkAgry = this.formData.checkAgry === 'yes'
        this.successRegistration()
        this.loadingBtn = false
      }).catch(e => {
        this.eventHub.$emit('ShowAlertMessage', {
          success: false,
          message: e.response.data.message
        })
        this.loadingBtn = false
      })
    },
    successRegistration () {
      this.eventHub.$emit('ShowAlertMessage', {
        success: true,
        message: 'Вы успешно зарегистрировались на сайте!'
      })
      setTimeout(() => {
        window.location.href = window.location.protocol + '//' + window.location.host + '/personal'
      }, 777)
    }
  }
}
