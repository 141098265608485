import { vueInvoker } from './VueInvoker'
import $ from 'jquery'

const baseComponent = {
  props: [
    'initial'
  ],
  created () {
    if (this['initial'] !== undefined) {
      for (let dataName in this.$data) {
        if (!this.$data.hasOwnProperty(dataName)) { continue }

        if (this.initial[dataName] !== undefined) {
          this.$data[dataName] = this.initial[dataName]
        }
      }
    }
  },
  updated () {
    vueInvoker.loadLazyImages(this.$el)
    vueInvoker.initAnchorClick(this.$el)
  },
  mounted () {
    if (this.$el.nodeType !== 8) {
      vueInvoker.loadLazyImages(this.$el)
      vueInvoker.initAnchorClick(this.$el)

      this.$on('scrollTo', function (target) {
        $('html, body').animate({
          scrollTop: $(target).offset().top - 100
        }, 1000)
      })
    }
  },
  methods: {
    getParameterByName (name, url = window.location.href) {
      return vueInvoker.getParameterByName(name, url)
    }
  }
}

export default baseComponent
